import React, { useState, useEffect } from "react";
import { string } from "prop-types";
import { AppLogo } from "../app-logo";
import ErrorMessage from "../../atoms/error-message";
import useStorage from "../../../hook/useStorage";
import useUrl from "../../../hook/useUrl";
import "./top-header.m.css";

const TopHeaderInfo = ({ pageType }) => {
  const [, currentUrlSlug] = useUrl();
  const [checkUser] = useStorage();
  const [wListed, setWlisted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState("");
  const [scrollDirection, setScrollDirection] = useState(null);
  const linkItems = [
    { href: '/', text: 'Ilkka-Pohjalainen', class: 'select-ip' },
    { href: '/epari', text: 'Epari', class: 'select-epari' },
    { href: '/vaasalehti', text: 'Vaasa-lehti', class: 'select-vaasa' },
  ];

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    } else {
      const listed = JSON.parse(window.localStorage.getItem("ipUser"));
      setWlisted(listed)
    }

  })

  useEffect(() => {
    setSelectOptions('Valitse lehti');
  }, [currentUrlSlug]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  let digilehtiSlug = "";
  if (currentUrlSlug !== undefined && currentUrlSlug !== "epari" && currentUrlSlug !== "vaasalehti") {
    digilehtiSlug = `/nakoislehti`;
  } else {
    digilehtiSlug = `/${currentUrlSlug}/nakoislehti`;
  }

  return (
    <div styleName="logo-row">
      <div styleName="logo">
        <AppLogo />
      </div>
      <div styleName="desktop-error-message"><ErrorMessage /></div>
      <div styleName="tablet-and-mobile-error-message"><ErrorMessage /></div>
      <div styleName="logo-row-right">
        <div styleName="custom-dropdown">
          <div styleName="selected-link" className="selected-link" onClick={toggleDropdown}>
            {selectOptions && selectOptions}
            <ul styleName={`dropdown-list ${isOpen ? (scrollDirection === "down" ? 'hide' : 'open') : 'hide'}`}>
              {linkItems.map((item) => (
                <li key={item.href} className={item.class}>
                  <a href={item.href}>{item.text}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

TopHeaderInfo.propTypes = {
  pageType: string
};

const TopHeader = TopHeaderInfo;

export default TopHeader;
