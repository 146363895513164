export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  AUTHOR_PAGE: "author-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  FORM_PAGE: "form-page",
  STATIC_PAGE: "static-page",
  VISUAL_STORY: "visual-story",
  TULO_LOGIN: "tulo-login",
  NATIVE_AD_CARD: "native-ad-card",
  BANNER_PAGE: "banner-page",
  URHEILU_IPPE_PAGE: "urheilu-ippe-page",
  KIEKKO_IPPE_PAGE: "kiekko-ippe-page",
  PESIS_IPPE_PAGE: "pesis-ippe-page",
  SALIBANDY_IPPE_PAGE: "salibandy-ippe-page",
  FUTIS_IPPE_PAGE : "futis-ippe-page",
  PROFILE_PAGE: "profile-page",
  ELECTION_PAGE: "election-page",
  ELECTIONRESULTS_PAGE: "electionresults-page",
  VAASA_HOME_PAGE: "vaasa-home-page",
  EPARI_HOME_PAGE: "epari-home-page",
  GAME_PAGE: "game-page",
  ELAMANTAPA_PAGE: "elamantapa-page",
  ACTIVE_SESSIONS: "active-sessions",
});

export const STORY_FIELDS =
  "headline,subheadline,summary,url,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,metadata,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template";
export const TAG_PAGE_URL_PREFIX = "/topic/";
