import React, { useRef, useEffect, useState } from 'react';

const LazyEagerImage = ({ src, srcSet, alt, styleName, className ,togglePopImage=false,togglePopImageFn}) => {
  const imageRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {// to determine if the image is in the viewport
            setIsIntersecting(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '0px 0px -50px 0px' } // 50px of the image scrolled up from the bottom of the viewport
    );
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <img
      ref={imageRef}
      src={isIntersecting ? src : ''}
      srcSet={isIntersecting ? srcSet : ''}
      alt={isIntersecting ? alt : undefined}
      loading={isIntersecting ? 'eager' : 'lazy'}
      styleName={styleName}
      className={className}
      style={{ transition: 'opacity 0.5s', opacity: isIntersecting ? 1 : 0 }}
      onClick={togglePopImage?togglePopImageFn:null}
    />
  );
};

export default LazyEagerImage;
