import React, { useEffect, useState, useRef } from "react";
import useUrl from "../../../hook/useUrl";
import { Link } from "@quintype/components";
import "./nav-scrollbar.m.css";

const NavScrollbar = ({ scrollNav, currentPath }) => {
  const [scrollStarted, setScrollStarted] = useState(false);
  const [scrollEnded, setScrollEnded] = useState(false);
  const innerWrapperRef = useRef(null);
  const [, currentUrlSlug] = useUrl();
  const activeItemRef = useRef(null);

  const filterOutTopicPages = currentPath.includes("topic") ? currentPath.replace(/\/topic\/[^/]+/, "") : currentPath;

  const parts = currentPath
    .replace(/%C3%A4/g, 'a') //Replace special characters in Seinäjoki URL
    .replace(/-ilkka-pohjalainen/g, '') //Remove -ilkka-pohjalainen from the URL (for Etelä-Pohjanmaa and Pohjanmaa collections)
    .split('/');

    //console.log("currentPath",currentPath);

  const lastSegment = parts.pop() || parts.pop(); //Find last segment of the URL
  //console.log("lastSegment",lastSegment);

  useEffect(() => {
    const handleScroll = () => {
      if (innerWrapperRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = innerWrapperRef.current;
        //console.log("scrollLeft:", scrollLeft, "scrollWidth:", scrollWidth, "clientWidth:", clientWidth);

        // Check if scroll has started
        if (scrollLeft >= 5) {
          setScrollStarted(true);
          //console.log("Scroll started");
        } else {
          setScrollStarted(false);
        }

        // Check if scroll has ended
        if (scrollLeft + clientWidth >= scrollWidth - 5) {
          setScrollStarted(false);
          setScrollEnded(true);
          //console.log("End reached");
        } else {
          setScrollEnded(false);
        }
      }
    };

    const innerWrapper = innerWrapperRef.current;
    if (innerWrapper) {
      innerWrapper.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (innerWrapper) {
        innerWrapper.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (activeItemRef.current && innerWrapperRef.current) {
      const activeItemRect = activeItemRef.current.getBoundingClientRect();
      const innerWrapperRect = innerWrapperRef.current.getBoundingClientRect();
      const scrollLeft = activeItemRect.left - innerWrapperRect.left + innerWrapperRef.current.scrollLeft - (innerWrapperRect.width / 2) + (activeItemRect.width / 2);

      innerWrapperRef.current.scrollTo({
        left: scrollLeft,
        behavior: "smooth"
      });
    }
  }, [currentPath]);

  return (

    <div styleName="wrapper">
      <div styleName="inner-wrapper" ref={innerWrapperRef}>
        <div styleName="menu-group">
          <nav styleName="nav">
          <React.Fragment>
              {scrollNav.length >= 1 && (
                <ul styleName={`menu-list ${scrollStarted ? "scroll-started" : (scrollEnded ? "scroll-ended" : "")}`}>
                  {scrollNav.map(item => {
                    const formattedTitle = item.title
                    .toLowerCase()
                    .replace(/ä/g, 'a')
                    .replace(/ö/g, 'o')
                    .replace(/\s+/g, '-');;
                    //console.log("formattedTitle",formattedTitle);
                    const isActive = currentPath.includes("topic")
                      && currentPath !== "/topic/sein%C3%A4joki" //Can be removed when Seinäjoki collection is added
                      && currentPath !== "/topic/vaasa" //Can be removed when Vaasa collection is added
                      && currentPath !== "/topic/paussi"
                      ? ""
                      : (lastSegment === formattedTitle || (item.title === "Ota yhteyttä" && lastSegment === "yhteystiedot") || (item.title === "Vaalikone" && lastSegment === "vaalit"))
                      ? "active"
                      : "";
                    const itemClassName = currentUrlSlug === "epari"
                      ? `menu-list-item-epari`
                      : currentUrlSlug === "vaasalehti"
                      ? `menu-list-item-vaasa`
                      : `menu-list-item`;
                    return item.isExternalLink && !(
                      item.completeUrl.includes("ilkkapohjalainen.fi") ||
                      item.completeUrl.includes("localhost") ||
                      item.completeUrl.includes("qtstage")
                    ) ? (
                      <li
                        styleName={`${itemClassName} ${isActive}`}
                        key={item.id}
                        ref={isActive ? activeItemRef : null}
                      >
                        <a target="_blank" styleName="menu-item-link" href={item.completeUrl || "/"} rel="noreferrer">
                          {item.title}
                        </a>
                      </li>
                    ) : (
                      <li
                          styleName={`${itemClassName} ${isActive}`}
                          key={item.id}
                          ref={isActive ? activeItemRef : null}
                        >
                        <Link styleName="menu-item-link" href={item.completeUrl || "/"}>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </React.Fragment>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavScrollbar;
